<template>
    <div>
        <!-- URL Input -->
        <v-row no-gutters justify="center">
            <v-col cols="6">
                <v-text-field
                    label="WordPress URL"
                    v-model="selectedConfiguration.wordpress_url"                   
                    outlined
                    dense
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="2" class="pl-1">
                <v-btn :disabled="!selectedConfiguration.wordpress_url" outlined @click="fetchTotalResults">
                    Check totaal
                </v-btn>
            </v-col>
        </v-row>

        <!-- Total Results and Fetch Content -->
        <v-row v-if="totalPagesCount > 0 || totalPostsCount > 0" no-gutters justify="center" class="pt-3">
            <v-col cols="6">
                <v-alert type="info" outlined>
                    Gevonden pagina's: {{ totalPagesCount }} resultaten over {{ totalPagesPages }} pagina's.
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="totalPostsCount > 0" no-gutters justify="center" class="pt-3">
            <v-col cols="6">
                <v-alert type="info" outlined>
                    Gevonden posts: {{ totalPostsCount }} resultaten over {{ totalPostsPages }} pagina's.
                </v-alert>
            </v-col>
        </v-row>
        <v-row no-gutters justify="center" class="pt-3">
            <v-col cols="6">
                <v-btn color="primary" outlined @click="fetchAllContent">
                    Haal alle content op
                </v-btn>
            </v-col>
        </v-row>

        <!-- Fetching Progress -->
        <v-row v-if="fetchingProgress > 0" no-gutters justify="center" class="pt-3">
            <v-col cols="6">
                <v-alert type="info" outlined>
                    Bezig met ophalen: {{ fetchingProgress }} / {{ totalPages }} pagina's.
                </v-alert>
            </v-col>
        </v-row>

        <!-- Collapsible: Retrieved Pages -->
        <v-row v-if="pages.length" no-gutters justify="center" class="pt-3">
            <v-col cols="8">
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Gevonden pagina's van WordPress ({{ pages.length }})
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card outlined>
                                <v-card-title>WordPress Pagina's</v-card-title>
                                <v-card-text style="max-height: 300px; overflow-y: auto;">
                                    <pre>{{ pages }}</pre>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <!-- Collapsible: Retrieved Posts -->
        <v-row v-if="posts.length" no-gutters justify="center" class="pt-3">
            <v-col cols="8">
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Gevonden posts van WordPress ({{ posts.length }})
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card outlined>
                                <v-card-title>WordPress Posts</v-card-title>
                                <v-card-text style="max-height: 300px; overflow-y: auto;">
                                    <pre>{{ posts }}</pre>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <!-- Collapsible: Retrieved from Weaviate -->
        <v-row v-if="indexedPages.length" no-gutters justify="center" class="pt-3">
            <v-col cols="8">
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Geïndexeerde pagina's in Weaviate ({{ indexedPages.length }})
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card outlined>
                                <v-card-title>Weaviate Pagina's</v-card-title>
                                <v-card-text style="max-height: 300px; overflow-y: auto;">
                                    <pre>{{ indexedPages }}</pre>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

       <!-- Action Buttons -->
        <v-row no-gutters justify="center" class="pt-3">
            <v-col cols="6">
                <v-btn :disabled="!pages.length && !posts.length" color="primary" outlined @click="indexContent">
                    Indexeer content
                </v-btn>
            </v-col>
            <v-col cols="6" class="pl-1">
                <v-btn color="error" outlined @click="deleteIndexedPages">
                    Verwijder geïndexeerde content
                </v-btn>
            </v-col>
        </v-row>      
    </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/eventBus";

export default {
    name: "WordpressSettings",
    data() {
        return {            
            pages: [],
            posts: [],
            indexedPages: [],
            perPage: 10,
            totalPagesCount: 0,
            totalPostsCount: 0,
            totalPagesPages: 0,
            totalPostsPages: 0,
            fetchingProgress: 0,
            };
    },
    computed: {
        selectedConfiguration() {
            return this.$store.getters.selectedConfiguration;
        },
    },
    methods: {
        async fetchTotalResults() {
            if (!this.selectedConfiguration.wordpress_url) return;

            EventBus.$emit("show-overlay");
            try {
                const token = localStorage.getItem("userToken");

                // Fetch total pages
                const pagesResponse = await axios.get(`${this.$config.configurationServer}/wordpress/total-pages`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { url: this.selectedConfiguration.wordpress_url },
                });

                // Fetch total posts
                const postsResponse = await axios.get(`${this.$config.configurationServer}/wordpress/total-posts`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { url: this.selectedConfiguration.wordpress_url },
                });

                if (pagesResponse.data.status === "OK" && postsResponse.data.status === "OK") {
                    this.totalPagesCount = pagesResponse.data.total_results;
                    this.totalPagesPages = pagesResponse.data.total_pages;

                    this.totalPostsCount = postsResponse.data.total_results;
                    this.totalPostsPages = postsResponse.data.total_pages;
                } else {
                    throw new Error("Kon het totaal aantal resultaten niet ophalen.");
                }
            } catch (error) {
                EventBus.$emit("show-snackbar", { type: "ERROR", message: error.message });
            } finally {
                EventBus.$emit("hide-overlay");
            }
        },

        async fetchAllPages() {
            if (!this.selectedConfiguration.wordpress_url) return;

            EventBus.$emit("show-overlay");
            try {
                const token = localStorage.getItem("userToken");

                this.pages = [];
                let page = 1;
                let morePagesAvailable = true;

                while (morePagesAvailable) {
                    console.log(`Fetching pages for page ${page}`);
                    const response = await axios.get(`${this.$config.configurationServer}/wordpress/pages`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: { url: this.selectedConfiguration.wordpress_url, page, per_page: this.perPage },
                    });

                    if (response.data.status === "OK" && response.data.pages?.length > 0) {
                        this.pages = [...this.pages, ...response.data.pages];
                        page++; // Increment page number
                    } else {
                        console.log("*** No more pages to fetch");
                        morePagesAvailable = false; // Stop fetching when no more pages
                    }
                }

                console.log(`Fetched ${this.pages.length} pages`);
            } catch (error) {
                console.error("Error fetching pages:", error);
                EventBus.$emit("show-snackbar", { type: "ERROR", message: error.message });
            } finally {
                EventBus.$emit("hide-overlay");
            }
        },

        async fetchAllPosts() {
            if (!this.selectedConfiguration.wordpress_url) return;

            EventBus.$emit("show-overlay");
            try {
                const token = localStorage.getItem("userToken");

                this.posts = [];
                let page = 1;
                let morePostsAvailable = true;

                while (morePostsAvailable) {
                    console.log(`Fetching posts for page ${page}`);
                    const response = await axios.get(`${this.$config.configurationServer}/wordpress/posts`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: { url: this.selectedConfiguration.wordpress_url, page, per_page: this.perPage },
                    });

                    if (response.data.status === "OK" && response.data.posts?.length > 0) {
                        this.posts = [...this.posts, ...response.data.posts];
                        page++; // Increment page number
                    } else {
                        console.log("*** No more posts to fetch");
                        morePostsAvailable = false; // Stop fetching when no more posts
                    }
                }

                console.log(`Fetched ${this.posts.length} posts`);
            } catch (error) {
                console.error("Error fetching posts:", error);
                EventBus.$emit("show-snackbar", { type: "ERROR", message: error.message });
            } finally {
                EventBus.$emit("hide-overlay");
            }
        },

        async fetchAllContent() {
            await this.fetchAllPages();
            await this.fetchAllPosts();

            console.log(`Total content fetched: ${this.pages.length} pages, ${this.posts.length} posts`);
        },

        async fetchIndexedPages() {
            if (!this.selectedConfiguration) return;

            EventBus.$emit("show-overlay");
            try {
                const token = localStorage.getItem("userToken");

                const response = await axios.get(
                    `${this.$config.configurationServer}/wordpress/get-indexed-pages`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        params: { configurationId: this.selectedConfiguration._id },
                    }
                );

                if (response.data.status === "OK") {
                    this.indexedPages = response.data.pages;
                }
            } catch (error) {
                EventBus.$emit("show-snackbar", { type: "ERROR", message: error.message });
            } finally {
                EventBus.$emit("hide-overlay");
            }
        },

        async indexContent() {
            if ((!this.pages.length && !this.posts.length) || !this.selectedConfiguration) return;

            EventBus.$emit("show-overlay");
            try {
                const token = localStorage.getItem("userToken");

                // Prepare request payload with both pages and posts
                const payload = {
                    configurationId: this.selectedConfiguration._id,
                    pages: this.pages,
                    posts: this.posts,
                    wordpressUrl: this.selectedConfiguration.wordpress_url,
                };

                // Send indexing request
                const response = await axios.post(`${this.$config.configurationServer}/wordpress/index-content`, payload, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.data.status === "OK") {
                    EventBus.$emit("show-snackbar", { type: "SUCCESS", message: response.data.message });
                    this.fetchIndexedPages(); // Refresh indexed pages after indexing
                }
            } catch (error) {
                EventBus.$emit("show-snackbar", { type: "ERROR", message: error.message });
            } finally {
                EventBus.$emit("hide-overlay");
            }
        },

        async deleteIndexedPages() {
            if (!this.selectedConfiguration) return;

            EventBus.$emit("show-overlay");
            try {
                const token = localStorage.getItem("userToken");

                const response = await axios.delete(
                    `${this.$config.configurationServer}/wordpress/delete-pages`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                        params: { configurationId: this.selectedConfiguration._id },
                    }
                );

                if (response.data.status === "OK") {
                    EventBus.$emit("show-snackbar", { type: "SUCCESS", message: response.data.message });
                    this.indexedPages = []; // Clear indexed pages
                }
            } catch (error) {
                EventBus.$emit("show-snackbar", { type: "ERROR", message: error.message });
            } finally {
                EventBus.$emit("hide-overlay");
            }
        },
    },
    mounted() {
        if (this.selectedConfiguration) {
            this.fetchIndexedPages(); // Fetch indexed pages on mount
        }
    },
};
</script>
