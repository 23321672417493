<template>
  <v-container>
    <v-row class="fill-height" justify="center">
      <v-col cols="12">
        <v-card class="window-card pa-5" flat>
          <!-- Agent Availability Toggle -->
          <v-row justify="center" class="mb-4" v-if="selectedConfiguration?.use_live_chat_monitor">
            <v-switch
              v-model="isAgentAvailable"
              :label="$t('available')"
              inset
              color="success"
              @change="toggleAgentAvailability"
            ></v-switch>
          </v-row>

          <!-- Tabs for Live and History -->
          <v-tabs v-model="activeTab" grow class="tabs-container">
            <v-tab v-if="selectedConfiguration?.use_live_chat_monitor" class="tab" :class="{ active: activeTab === 0 }">
              <span style="color:white;" v-if="activeTab === 0">{{ $t('ongoing_conversations') }} ({{ liveConversationsCount }})</span>
              <span style="color:black;" v-if="activeTab != 0">{{ $t('ongoing_conversations') }} ({{ liveConversationsCount }})</span>
            </v-tab>
            <v-tab class="tab" :class="{ active: activeTab === 1 }">
              <span style="color:white;" v-if="activeTab === 1">{{ $t('conversation_history') }}</span>
              <span style="color:black;" v-if="activeTab != 1">{{ $t('conversation_history') }}</span>
            </v-tab>
            <v-tab v-if="selectedConfiguration?.use_post_processing" class="tab" :class="{ active: activeTab === 2 }">
              <span style="color:white;" v-if="activeTab === 2">{{ $t('postprocessed_results') }}</span>
              <span style="color:black;" v-if="activeTab != 2">{{ $t('postprocessed_results') }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="activeTab">
            <!-- Live Conversations Tab -->
            <v-tab-item v-if="selectedConfiguration?.use_live_chat_monitor">
              <live-conversations :configuration-id="configurationId" />
            </v-tab-item>

            <!-- History Conversations Tab -->
            <v-tab-item>
              <history-conversations :configuration-id="configurationId" />
            </v-tab-item>
            <!-- Postprocesses results Tab -->
            <v-tab-item>
              <post-processed-results :configuration-id="configurationId" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import io from 'socket.io-client';
import LiveConversations from './conversations/live.vue';
import HistoryConversations from './conversations/history.vue';
import PostProcessedResults from './conversations/postprocessed.vue';
import { EventBus } from '../eventBus.js';

export default {
  name: 'ConversationsTab',
  components: {
    LiveConversations,
    HistoryConversations,
    PostProcessedResults
  },
  props: {
    configurationId: String,
    useLiveChatMonitor: Boolean
  },
  data() {
    return {
      activeTab: 0, // Default to the first tab
      liveConversationsCount: 0, // Count of ongoing conversations
      isAgentAvailable: false, // Track agent availability,
      socket: null, // Socket.io instance
      activeConversations: new Set(), // Track active session_ids
    };
  },
  computed: {
    selectedConfiguration: {
      get() {
        return this.$store.getters.selectedConfiguration;
      }
    },
  },
  created() {
    this.setupSocketConnection();

    EventBus.$on('set-configuration', () => {
      // Reset socket connection for new configuration
      if (this.socket) {
        this.liveConversationsCount = 0;
        this.socket.disconnect();
        this.socket = null;
      }
      this.setupSocketConnection();

      if (!this.selectedConfiguration?.use_live_chat_monitor) {
        this.activeTab = 0;
      } else { this.activeTab = 1; }
    });
  },
  methods: {
    setupSocketConnection() {
      // Initialize Socket.io with token
      const token = localStorage.getItem('userToken');
      this.socket = io(`${this.$config.chatServer}/live`, {
        query: { token, configurationId: this.configurationId },
        transports: ['websocket'],
      });

      this.socket.on('connect', () => {
        console.log('Socket connected');
      });

      this.socket.on('conversation_update', (data) => {
        this.handleConversationUpdate(data);
      });

      this.socket.on('connect_error', (err) => {
        console.error('Socket connection error:', err.message);
      });

      EventBus.$on('manual-takeover', (conversation) => {
        console.log('*** Manual takeover event received');
        this.socket.emit('manual_takeover', {
          configuration_id: conversation.configuration_id,
          session_id: conversation.id,
        });
      });

      EventBus.$on('human-agent-message', (data) => {        
        this.socket.emit('human_agent_message', {
          configurationId: data.configurationId,
          conversationId: data.conversationId,
          messageContent: data.messageContent,
        });
      });
    },
    handleConversationUpdate(data) {
      const { session_id, configuration_id, is_active, ...payload } = data;
      // Add or remove active conversations
      if (is_active) {
        if (!this.activeConversations.has(session_id)) {
          this.activeConversations.add(session_id);
          this.liveConversationsCount++;
        }
      } else {
        if (this.activeConversations.has(session_id)) {
          this.activeConversations.delete(session_id);
          this.liveConversationsCount--;
        }
      }

      // Emit Vue event to inform child components
      EventBus.$emit('update-live-conversation', {
        session_id,
        configuration_id,
        is_active,
        ...payload,
      });
    },
    toggleAgentAvailability() {
      // Emit agent availability change to the backend
      this.socket.emit('change_agent_presence', {
        is_available: this.isAgentAvailable,
      });
      console.log(`Agent availability set to ${this.isAgentAvailable}`);
    },
  },
  beforeDestroy() {
    // Disconnect socket on component destroy
    if (this.socket) {
      this.socket.disconnect();
    }
  },
};
</script>

<style scoped>
.window-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
}

/* Tabs */
.tabs-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.tab {
  padding: 12px 20px;
  text-align: center;
  font-weight: 500;
  color: #5f6368;
  transition: all 0.3s ease;
  background-color: #e3e3e3;
}

.tab:hover {
  background-color: #64577c5e;
}

.tab.active {
  background-color: #1d0d3e;
  color: white;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
}
</style>