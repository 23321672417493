<template>
  <v-container>
    <v-row no-gutters>
      <!-- Left Section: Filter -->
      <v-col cols="6" md="4" class="filter-section">
        <v-card flat class="pa-4">
          <v-toolbar rounded dark dense>
            <v-spacer />
            <v-toolbar-title>
              {{ $t('Filter') }}
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3"></v-divider>

          <!-- Filters Section -->
          <v-row>
            <v-col cols="6">
              <div class="filter-group mb-4">
                <v-radio-group
                  v-model="selectedDateFilter"
                  @change="updateDateFilter"
                  class="filter-radio-group"
                >
                  <v-radio :label="$t('today')" value="today"></v-radio>
                  <v-radio :label="$t('yesterday')" value="yesterday"></v-radio>
                  <v-radio :label="$t('last_week')" value="last_week"></v-radio>
                  <v-radio :label="$t('custom')" value="custom"></v-radio>
                </v-radio-group>

                <!-- Custom Date Range -->
                <div v-if="selectedDateFilter === 'custom'">
                  <v-menu
                    ref="menuFrom"
                    v-model="menuFrom"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="$t('date_from')"
                        v-model="dateFrom"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="mdi-calendar"
                        outlined
                        dense
                        hide-details="true"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tempDateFrom"
                      :max="maxDate"
                      @input="setDateFrom($event)"
                      no-title
                      show-adjacent-months
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="menuTo"
                    v-model="menuTo"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="$t('date_to')"
                        v-model="dateTo"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="mdi-calendar"
                        outlined
                        dense
                        hide-details="true"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tempDateTo"
                      :max="maxDate"
                      @input="setDateTo($event)"
                      no-title
                      show-adjacent-months
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
            </v-col>
          </v-row>

          <!-- Search Button -->
          <v-btn block class="search-button mt-4" color="primary" @click="searchPostProcessedConversations">
            {{ $t('search') }}
            <v-icon right>mdi-magnify</v-icon>
          </v-btn>
        </v-card>
      </v-col>

      <!-- Right Section: Post-Processed Results Table -->
      <v-col cols="6" md="8">
        <v-card flat class="pa-4">
          <v-toolbar flat dense>
            <v-toolbar-title>{{ $t('results') }}</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mb-3"></v-divider>

          <v-simple-table dense fixed-header>
            <thead>
              <tr>
                <th>{{ $t('date') }}</th>                
                <th>{{ $t('Type') }}</th>
                <th>{{ $t('actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="result in postProcessedResults" :key="result.session_id">
                <td>{{ formatDate(result.date_processed) }}</td>                
                <td>{{ result.type || 'Naverwerking' }}</td>
                <td>
                  <v-btn small @click="viewDetails(result)">{{ $t('view') }}</v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <div v-if="postProcessedResults.length === 0" class="empty-message">
            {{ $t('no_results_found') }}
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Details Dialog -->
    <v-dialog v-if="selectedResult" v-model="dialogVisible" persistent max-width="600px" style="overflow-y: auto;">
      <v-card>
        <v-card-title class="dialog-header">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <h2 class="dialog-title">
              {{ $t('post_processed_details') }}
            </h2>
          </v-col>
          <v-spacer></v-spacer>
        </v-card-title>

        <!-- Date Section -->
        <v-subheader class="dialog-date">               
          {{ formatDate(selectedResult?.date_processed) }}
        </v-subheader>

         <!-- Dialog Content -->
         <v-card-text class="dialog-content">
          <div v-html="selectedResult.post_process_result"></div>
        </v-card-text>       

         <!-- Dialog Footer -->
         <v-card-actions class="dialog-footer">
          <v-layout column align-center>
            <v-btn
              elevation="5"
              color="#E140A0"
              :style="`border: 2px solid #AF2C7B; border-radius:15px; color:white; padding:10px 30px; font-weight:bolder;`"
              @click="dialogVisible = false"
            >
              {{ $t('close') }}
            </v-btn>
          </v-layout>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { EventBus } from "../../eventBus.js";


export default {
  name: "PostProcessedResults",
  props: {
    configurationId: String,
  },
  data() {
    return {
      menuFrom: false,
      menuTo: false,
      dateFrom: null,
      tempDateFrom: null,
      dateTo: null,
      tempDateTo: null,
      maxDate: null,
      selectedDateFilter: "today",
      postProcessedResults: [],
      dialogVisible: false,
      selectedResult: null,
    };
  },
  mounted() {
    // Initialize date filters on component load
    this.updateDateFilter();
    this.searchPostProcessedConversations();
  },
  created() {    
    EventBus.$on('set-configuration', () => {      
      this.dateFrom = null;
      this.dateTo = null;
      this.tempDateFrom = null;
      this.tempDateTo = null;
      this.selectedDateFilter = "today";
      this.postProcessedResults = [];
      this.dialogVisible = false;
      this.selectedResult = null;    
      this.updateDateFilter();
      this.searchPostProcessedConversations();
    });
  },
  methods: {
    async searchPostProcessedConversations() {
      try {
        const token = localStorage.getItem("userToken");
        const payload = {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          configurationId: this.configurationId,
        };

        const response = await axios.post(
          `${this.$config.configurationServer}/chatoverview/search_postprocessed`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        this.postProcessedResults = response.data;
      } catch (error) {
        console.error("Error fetching post-processed conversations:", error);
      }
    },
    setDateFrom(newVal) {
      if (newVal > this.dateTo) {
        this.dateTo = newVal;
      }
      this.dateFrom = newVal;
    },
    setDateTo(newVal) {
      if (newVal < this.dateFrom) {
        this.dateFrom = newVal;
      }
      this.dateTo = newVal;
    },
    updateDateFilter() {
      const today = new Date();
      if (this.selectedDateFilter === "today") {
        this.dateFrom = this.dateTo = today.toISOString().substr(0, 10);
      } else if (this.selectedDateFilter === "yesterday") {
        const yesterday = new Date(today.setDate(today.getDate() - 1));
        this.dateFrom = this.dateTo = yesterday.toISOString().substr(0, 10);
      } else if (this.selectedDateFilter === "last_week") {
        const lastWeekStart = new Date(today.setDate(today.getDate() - 7));
        this.dateFrom = lastWeekStart.toISOString().substr(0, 10);
        this.dateTo = new Date().toISOString().substr(0, 10);
      }
    },
    viewDetails(result) {
      this.selectedResult = result;
      this.dialogVisible = true;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
</script>

<style scoped>
.filter-section {
  border-right: 1px solid #ddd;
  padding-bottom: 16px;
}
.search-button {
  transition: all 0.3s;
}
.search-button:hover {
  background-color: #51456b !important;
  color: white;
}
.empty-message {
  text-align: center;
  color: #757575;
  margin-top: 16px;
}

.dialog-content {
  max-height: 50vh;
  overflow-y: auto;

  border: 1px solid #b5b5b5;
  border-radius: 5px;
}
</style>
